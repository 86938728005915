<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin phiếu giữ hàng'">
      <template v-slot:preview>
        <b-row class="mb-5">
          <!-- LEFT INPUT CONTROLS -->
          <b-col lg="9" md="9" sm="12">
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group class="required-control" :disabled="!editable">
                  <label>Kho:</label>
                  <vue-autosuggest
                    class="border-radius-none"
                    :suggestions="filteredOptions"
                    @selected="onSelectedStore"
                    :limit="200"
                    @input="onInputChange"
                    :input-props="inputPropStore"
                    v-model="$v.searchStore.$model"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      <div style="{ display: 'flex', color: 'navyblue'}">
                        {{ suggestion.item.suggestionName }}
                      </div>
                    </div>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="required-control" :disabled="!editable">
                  <label>Điện thoại:</label>
                  <vue-autosuggest
                    :suggestions="filteredOptionsCustomer"
                    @selected="onSelectedCustomer"
                    :limit="10"
                    @input="onInputChangCustomer"
                    :input-props="inputSearchCustomerProp"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                    v-model="searchCustomer"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Họ tên KH: </label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="customerName"
                    placeholder="Họ tên khách hàng"
                    :disabled="!editable"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Địa chỉ:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="customerAddress"
                    placeholder="Địa chỉ khách hàng"
                    :disabled="!editable"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-form-group>
                  <label>Ghi chú:</label>
                  <b-form-textarea
                    size="sm"
                    v-model="description"
                    :placeholder="'Thêm nội dung ghi chú...'"
                    :rows="6"
                    :max-rows="6"
                    :disabled="!editable"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12" class="pr-0">
                <b-form-select
                  v-model="selectTypeSearch"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  @change="onChangeSearchType"
                  :disabled="!editable"
                ></b-form-select>
              </b-col>
              <b-col lg="9" md="9" sm="12" class="pl-0">
                <vue-autosuggest
                  v-model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  @selected="onSelectedProduct"
                  :limit="10"
                  @input="onInputChangProduct"
                  :input-props="inputSearchProductProp"
                  :should-render-suggestions="
                    (size, loading) =>
                      size >= 0 && !loading && searchProduct !== ''
                  "
                >
                  <div
                    v-if="selectTypeSearch === 1"
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div>
                      <span
                        v-if="suggestion.item.quantityInStock > 0"
                        class="text-success"
                        >Tồn: {{ suggestion.item.quantityInStock }}</span
                      >
                      <span v-else class="text-danger"
                        >Tồn: {{ suggestion.item.quantityInStock }}</span
                      >
                      -
                      {{ suggestion.item.productName }}
                    </div>
                  </div>
                  <div
                    v-else-if="selectTypeSearch === 2"
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div>
                      {{ suggestion.item.productName }}-
                      <span class="text-warning"
                        >IMEI: {{ suggestion.item.imeiCode }}</span
                      >
                    </div>
                  </div>
                </vue-autosuggest>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table
          class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          "
        >
          <thead>
            <tr>
              <th scope="col" class="title-center" style="width: 15%">
                Tên sản phẩm
              </th>
              <th scope="col" class="title-center" style="width: 5%">Tồn</th>
              <th scope="col" class="title-center" style="width: 20%">IMEI</th>
              <th scope="col" class="title-center" style="width: 10%">
                Số lượng
              </th>
              <th scope="col" class="title-center" style="width: 18%">
                Đơn giá
              </th>
              <th scope="col" class="title-center" style="width: 14%">
                Thành tiền
              </th>
              <th scope="col" class="title-center" style="width: 13%">
                Chiết khấu
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 5%"
                v-if="editable"
              ></th>
            </tr>
          </thead>

          <tbody v-for="item in listProductStock" :key="item.id">
            <ImportStock
              v-bind:productItem="item"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
              v-bind:editState="editable"
            />
          </tbody>
          <tr>
            <td :colspan="3" class="tdTextAlignPrice">Tổng</td>
            <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
            <td></td>
            <td class="tdTextAlignPrice">{{ formatMoney(totalPrice) }}</td>
            <td class="tdTextAlignPrice">
              {{ formatMoney(totalDiscount) }}
            </td>
            <td v-if="editable"></td>
          </tr>
        </table>

        <div class="col-md-12" style="padding-left: 0px">
          <div class="mb-5 mt-5">
            <b-button
              v-show="listProductStock.length > 0"
              v-bind:style="btnCreate"
              variant="primary"
              size="sm"
              @click="onSubmit"
              v-if="editable"
            >
              Lưu
            </b-button>
            <router-link to="/holding-stock" tag="button">
              <b-button variant="secondary" size="sm" style="width: 70px">
                Trở về
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.input-group-append {
  cursor: pointer;
  padding-top: 5.5px;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
// import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '../../components/ImportStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import { TIME_TRIGGER } from './../../../utils/constants';

import decounce from 'debounce';

export default {
  mixins: [validationMixin],
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '10px',
        width: '70px',
      },
      isNew: true,
      listProduct: [],
      description: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm theo IMEI',
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inventorySelected: null,
      inputSearchProductProp: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      formater: {
        currency: currencyMask,
      },
      isSearching: false,
      inputPropStore: {
        id: 'autosuggest__input',
        placeholder: 'Chọn kho',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      customerName: '',
      customerAddress: '',
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      selectedClassCustomer: null,
      inputSearchCustomerProp: {
        id: 'autosuggest__input',
        placeholder: 'SĐT Khách hàng',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      id: null,
      editable: true,
    };
  },
  validations: {
    searchStore: { required },
  },
  components: {
    KTCodePreview,
    ImportStock,
  },
  created() {
    this.fetchStoreByUser();
  },
  mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : null;
    const title = this.id ? 'Thông tin phiếu giữ hàng' : 'Tạo phiếu giữ hàng';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu giữ hàng', route: '/import-warranty-stocks' },
      { title: title },
    ]);
    if (this.id) {
      this.getStockById();
    }
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
  },
  methods: {
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.productName;
      if (this.selectedClassProduct.quantityInStock <= 0) {
        this.makeToastFaile('Số lượng tồn kho sản phẩm không hợp lệ!');
        return;
      }
      if (
        this.selectedClassProduct.productType === 3 ||
        this.selectedClassProduct.productType === 4
      ) {
        this.makeToastFaile(
          'Không hỗ trợ nhập kho sản phẩm combo và sản phẩm dịch vụ!'
        );
        return;
      }
      let imeiCode = '';
      if (this.selectTypeSearch === 2) {
        imeiCode = this.selectedClassProduct.imeiCode
          ? this.selectedClassProduct.imeiCode
          : '';
      }
      const pro = {
        id: this.$uuid.v4(),
        name: this.selectedClassProduct.productName,
        totalQuantityInStock: this.selectedClassProduct.quantityInStock
          ? this.selectedClassProduct.quantityInStock
          : 0,
        IMEI: imeiCode,
        proId: this.selectedClassProduct.productId,
        price: this.selectedClassProduct.sellingPrice,
        quantity: 1,
        discount: 0,
        totalPrice: this.selectedClassProduct.sellingPrice,
        productType: this.selectedClassProduct.productType,
        productCode: this.selectedClassProduct.productCode,
        barCode: this.selectedClassProduct.barCode,
      };
      this.listProductStock.unshift(pro);
      this.searchProduct = '';
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onSubmit: async function () {
      if (this.searchStore.trim() === '') {
        this.makeToastFaile(`Vui lòng chọn kho!`);
        return;
      }
      if (!this.searchCustomer.trim()) {
        this.makeToastFaile(`Vui lòng nhập SĐT khách hàng!`);
        return;
      }
      const listPro = this.listProductStock.map((element) => {
        return {
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
          discount: unMaskPrice(element.discount),
        };
      });
      const data = {
        storeId: this.inventorySelected,
        customerName: this.customerName,
        customerPhone: this.searchCustomer,
        customerAddress: this.customerAddress,
        description: this.description,
        listProduct: listPro,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post('stock-slips/holding-stock-slip', data)
          .then(({ data }) => {
            this.isNew = true;
            if (data.status === 1) {
              this.makeToastSuccess(data.message);
              setTimeout(() => {
                this.$router.push({
                  name: 'info-holding-stock',
                  query: { id: data.data },
                });
                this.id = data.data;
                this.getStockById();
              }, TIME_TRIGGER);
            } else {
              this.makeToastFaile(data.message);
              this.isNew = true;
            }
          })
          .catch((response) => {
            this.isNew = true;
            this.makeToastFaile(response.$error);
          });
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.get(
        `productSearch?storeId=${this.inventorySelected}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          let nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = item.quantity;
          this.listProductStock[index].discount = unMaskPrice(item.discount);
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
    },
    fetchProductImei(textSearch) {
      this.listProduct = [];
      ApiService.get(
        `productSearch/search-by-imei?storeId=${this.inventorySelected}&imeiCode=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          const nextSearchProduct = this.searchProduct;
          this.fetchProductImei(nextSearchProduct);
        }
        if (products.length === 1) {
          this.selectedClassProduct = products[0];
          const pro = {
            id: this.$uuid.v4(),
            name: this.selectedClassProduct.productName,
            totalQuantityInStock: this.selectedClassProduct.quantityInStock
              ? this.selectedClassProduct.quantityInStock
              : 0,
            IMEI: this.selectedClassProduct.imeiCode
              ? this.selectedClassProduct.imeiCode
              : '',
            proId: this.selectedClassProduct.productId,
            price: this.selectedClassProduct.sellingPrice,
            quantity: 1,
            discount: 0,
            totalPrice: this.selectedClassProduct.sellingPrice,
            productType: this.selectedClassProduct.productType,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
          };
          this.listProductStock.unshift(pro);
        }
      });
    },
    onChangeStore() {
      this.listProductStock = [];
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.inputSearchProductProp.disabled = false;
      this.onChangeStore();
    },
    onInputChange(text) {
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    searchProductAPI() {
      if (!this.isSearching) {
        const textSearch = this.searchProduct;
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei(textSearch);
        } else {
          this.fetchProduct(textSearch);
        }
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStore = stores[0].name;
            this.inventorySelected = stores[0].id;
            this.inputPropStore.disabled = true;
            this.inputSearchProductProp.disabled = false;
          } else {
            this.options[0].data = [];
            stores.map((element) => {
              const store = {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: element.shortName + ' ( ' + element.name + ')',
              };
              this.options[0].data.push(store);
            });
            const tmp = [{ data: this.options[0].data }];
            this.filteredOptions = tmp;
          }
        }
      });
    },
    getCustomerInfoById() {
      ApiService.get(`customer/${this.selectedClassCustomer}`).then((data) => {
        const customerInfo = data.data.data;
        this.customerName = customerInfo.fullName;
        this.customerAddress = customerInfo.address ? customerInfo.address : '';
      });
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.selectedClassCustomer = option.item.id;
      this.getCustomerInfoById();
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [
        {
          data: this.optionsCustomer[0].data,
        },
      ];
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      this.debounceInput();
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      let text = this.searchCustomer;
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          this.listCustomer = customers;
          customers.map((element) => {
            let cus = {
              id: element.id,
              phoneNo: element.phoneNo,
              fullName: element.fullName,
              suggestionName: element.phoneNo + ' - ' + element.fullName,
            };
            this.optionsCustomer[0].data.push(cus);
          });
          this.filteredOptionsCustomer = [
            {
              data: this.optionsCustomer[0].data,
            },
          ];
        }
      );
    },
    onChangeSearchType() {
      if (this.selectTypeSearch === 1) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo mã vạch, mã, tên sản phẩm';
      } else if (this.selectTypeSearch === 2) {
        this.inputSearchProductProp.placeholder = 'Tìm kiếm theo mã IMEI';
      }
      this.debounceInputProduct();
    },
    getStockById() {
      this.editable = false;
      ApiService.get(`warranty-stock/${this.id}`)
        .then(({ data }) => {
          this.inputSearchProductProp.disabled = true;
          const dataRes = data.data;
          this.codeStockBill = dataRes.code;
          this.inventorySelected = dataRes.storeId;
          this.searchStore = dataRes.storeName;
          this.selectedClassCustomer = dataRes.providerId;
          this.customerName = dataRes.customerName;
          this.searchCustomer = dataRes.customerPhone;
          this.customerAddress = dataRes.customerAddress;
          this.description = dataRes.description;
          this.listProductStock = [];
          this.listProductStock = dataRes.listDetail.map((element) => {
            let listImei = element.productImei.split(',');
            let stt = 1;
            let formatImei = '';
            listImei.forEach((element, index) => {
              formatImei += stt + ')  ' + element;
              if (index !== listImei.length - 1) {
                formatImei += ',';
              }
              stt++;
            });
            return {
              id: element.id,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
              productType: element.productType,
              formatImei: formatImei.replace(/[,]/g, '\n').replace(/[ ]/g, ''),
              totalQuantityInStock:
                element.totalQuantityInStock != null
                  ? element.totalQuantityInStock
                  : 0,
              discount: element.discount ? element.discount : 0,
              barCode: element.barCode ? element.barCode : '',
              productCode: element.productCode ? element.productCode : '',
            };
          });
        })
        .catch((response) => {
          this.makeToastFaile(response.$error);
        });
    },
  },
};
</script>
